<template>
  <div class="flex flex-row items-center">
    <app-txt variant="small" class="ml-1">{{ tableFormat }}</app-txt>
  </div>
  <app-txt
    v-if="showCountdown"
    variant="tiny"
    class="ml-1 font-medium text-orange-600"
    >{{ countdownText }}</app-txt
  >
</template>

<script lang="ts">
import { DateWrapper } from '@/core';
import { t } from '@/services/i18n';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: DateWrapper,
    displayTime: { type: Boolean, default: false },
  },
  setup(props) {
    const tableFormat = computed(() => {
      return props?.displayTime
        ? props.value?.toTableFormatWithHours()
        : props.value?.toTableFormat();
    });

    const calculateDaysRemaining = (endDate: Date) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const end = new Date(endDate);
      end.setHours(0, 0, 0, 0);
      const timeDiff = end.getTime() - today.getTime();
      return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    };

    const daysRemaining = computed(() => {
      const endDate = props.value?.getValue();
      return calculateDaysRemaining((endDate as unknown) as Date);
    });

    const showCountdown = computed(
      () => daysRemaining.value <= 14 && daysRemaining.value >= 0,
    );
    const countdownText = computed(() =>
      t('overview.remainingDays', { countdown: daysRemaining.value }),
    );

    return { tableFormat, showCountdown, countdownText };
  },
});
</script>
