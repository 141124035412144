<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="closeModal"
    :title="t('title')"
    :cancelLabel="t('cancel')"
    :confirmLabel="t('save')"
    :onConfirm="onEditEnabledNotifications"
    :isLoading="isLoading"
  >
    <div
      class="grid gap-x-5 gap-y-3 grid-cols-1 md:grid-cols-2 justify-between mb-5"
    >
      <app-txt variant="small" class="text-gray-700">
        {{ t('generalNotifications') }}
      </app-txt>
      <Toggle
        v-model="allNotificationsEnabled"
        :classes="{
          container:
            'flex justify-center align-center rounded-full outline-none border-primary-750 justify-items-end',
        }"
      />
    </div>
  </modal>
</template>

<script lang="ts">
import { defineComponent, onUpdated, ref } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { notificationsTranslateByScope } from './translationScope';
import { useUpdateEnabledNotifications } from '@/features/users/application/useUpdateEnabledNotifications';
import Toggle from '@vueform/toggle';
import { EnabledNotifications } from '../domain';

export default defineComponent({
  props: {
    isModalOpen: { type: Boolean, required: true },
    userNotifications: {
      type: Object as () => EnabledNotifications,
      required: true,
    },
    userId: { type: String, required: true },
  },
  components: {
    Toggle,
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    const { isLoading, call } = useUpdateEnabledNotifications();

    const allNotificationsEnabled = ref(props.userNotifications.allEnabled);
    const t = notificationsTranslateByScope('notifications');
    const hasSubmitted = ref(false);

    const closeModal = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onEditEnabledNotifications = () => {
      hasSubmitted.value = true;
      call({
        id: props.userId,
        enabledNotifications: {
          motivesNotifications: props.userNotifications.motivesNotifications,
          motivesEditingNotifications:
            props.userNotifications.motivesEditingNotifications,
          motivesDeactivationNotifications:
            props.userNotifications.motivesDeactivationNotifications,
          campaignsNotifications:
            props.userNotifications.campaignsNotifications,
          allEnabled: allNotificationsEnabled.value,
        },
      });
      closeModal();
    };

    onUpdated(() => {
      setTimeout(() => {
        hasSubmitted.value = false;
      }, 300);
    });

    return {
      t,
      onEditEnabledNotifications,
      hasSubmitted,
      closeModal,
      Toggle,
      isLoading,
      props,
      allNotificationsEnabled,
    };
  },
});
</script>
